import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NIMBLE_URL } from "../../../../helpers/variables";
import { enqueueSnackbar } from "notistack";

const PaymentType = ({ reviewForm, setReviewForm, HEADERS }) => {
  const { property, vendor, transactionType } = reviewForm;
  const [paymentTypeList, setPaymentTypeList] = useState([]);

  useEffect(() => {
    getPaymentTypeList();
  }, [property]);

  async function getPaymentTypeList() {
    if (property) {
      const CorporationID = property.corporationID;
      setPaymentTypeList([]);
      try {
        let res = await fetch(`${NIMBLE_URL}/GetPaymentTypeList`, {
          method: "POST",
          headers: HEADERS,
          body: JSON.stringify({ CorporationID }),
        });
        let data = await res.json();
        setPaymentTypeList(data["types"]);
      } catch (error) {
        enqueueSnackbar(`Error fetching payment type list:${error}`, {
          variant: "error",
        });
      }
    }
  }

  useEffect(() => {
    if (vendor) {
      getDefaultPaymentType();
    }
  }, [vendor]);

  function getDefaultPaymentType() {
    const defaultPaymentType = paymentTypeList.find(
      (i) =>
        i["id"] === vendor["paymentMethodID"] ||
        i["name"].toLowerCase() === "check"
    );
    updatePaymentType(defaultPaymentType);
  }

  /**
   * @param {Event} e
   */
  function handleOnChange(e) {
    updatePaymentType(e.target.value);
  }

  function updatePaymentType(payment_type) {
    setReviewForm((prev) => ({ ...prev, payment_type }));
  }

  return (
    <FormControl size="small">
      <InputLabel>Payment Type</InputLabel>
      <Select
        name="payment_type"
        label="Payment Type"
        onChange={handleOnChange}
        value={reviewForm["payment_type"] || ""}
      >
        {paymentTypeList.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PaymentType;
