import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { NIMBLE_URL } from "../../../../helpers/variables";
import { enqueueSnackbar } from "notistack";

const BillPayTable = ({ reviewForm, setReviewForm, HEADERS }) => {
  const { vendor, property } = reviewForm;

  const [billsList, setBillList] = useState([]);

  useEffect(() => {
    setBillList([]);
    if (vendor) {
      fetchBillList();
    }
  }, [vendor]);

  async function fetchBillList(params) {
    let today = new Date();
    let payload = {
      CorporationID: property["corporationID"],
      Domain: HEADERS.Domain,
      ApprovalStatus: 2,
      Paging: 0,
      PaymentStatus: 1,
      ToDate:
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate(),
      VendorID: vendor.id.replace("0x", ""),
    };

    try {
      let res = await fetch(`${NIMBLE_URL}/Getbillslist`, {
        method: "POST",
        headers: HEADERS,
        body: JSON.stringify(payload),
      });
      let data = await res.json();
      data.forEach((i) => {
        let split_date = i["date"]?.split("T");
        let split_due_date = i["dueDate"]?.split("T");
        i["date_only"] = split_date[0];
        i["due_date_only"] = split_due_date[0];
      });
      setBillList(data);
    } catch (error) {
      enqueueSnackbar(`Error fetching bill pay list: ${error}`, {
        variant: "error",
      });
    }
  }

  const columns = [
    { field: "invoiceNumber", headerName: "Invoice Number", flex: 1 },
    { field: "date_only", headerName: "Date", flex: 1 },
    { field: "due_date_only", headerName: "Due Date", flex: 1 },
    { field: "amount", headerName: "Amount", flex: 1 },
  ];

  return (
    <DataGrid
      autoHeight
      sx={{ minHeight: "100px" }}
      checkboxSelection
      getRowId={(row) => row.billID}
      rows={billsList}
      density="compact"
      columns={columns}
      hideFooter
      onRowSelectionModelChange={(ids) => {
        const selectedIDs = new Set(ids);

        const selectedBillsToPay = billsList.filter((row) =>
          selectedIDs.has(row.billID)
        );

        let invoice_amount = selectedBillsToPay.reduce(
          (acc, obj) => acc + Number(obj.amount),
          0
        );

        setReviewForm((prev) => ({
          ...prev,
          invoice_amount,
        }));

        setReviewForm((prev) => ({
          ...prev,
          selectedBillsToPay,
        }));
      }}
    />
  );
};

export default BillPayTable;
