import React, { useEffect, useState } from "react";
import { NIMBLE_URL } from "../../../../helpers/variables";
import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
// import { validateContract } from "../validation/individual";

const Contract = ({ reviewForm, setReviewForm, HEADERS }) => {
  const vendor = reviewForm.vendor;

  const [contractList, setContractList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    updateContract(null);
    getContractList();
  }, [vendor]);

  async function getContractList() {
    setLoading(true);
    if (vendor) {
      const VendorID = vendor.id;
      try {
        let res = await fetch(`${NIMBLE_URL}/contractlist`, {
          method: "POST",
          headers: HEADERS,
          body: JSON.stringify({ VendorID }),
        });
        /**
         * @type {Array<Object>}
         */
        let data = await res.json();
        if (data.length === 1) {
          updateContract(data[0]);
        }
        setContractList(data);
      } catch (error) {
        enqueueSnackbar(`Error fetching contract list:${error}`, {
          variant: "error",
        });
      }
    }
    setLoading(false);
  }

  function updateContract(newContract) {
    setReviewForm((prev) => ({ ...prev, contract: newContract }));
  }

  const contract = reviewForm["contract"];
  // const validate = validateContract(contract);

  return !loading ? (
    <Autocomplete
      value={contract}
      size="small"
      options={contractList}
      disabled={contractList.length <= 1}
      getOptionLabel={(option) =>
        option.contractAccountNumber !== ""
          ? option.contractAccountNumber
          : "NA"
      }
      onChange={(_, v) => updateContract(v)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Contracts"
          // error={Boolean(validate)}
          // helperText={validate}
        />
      )}
    />
  ) : (
    <Box display={"grid"} sx={{ placeItems: "center" }}>
      <CircularProgress />
    </Box>
  );
};

export default Contract;
