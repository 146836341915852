import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BACKEND_URL } from "../../../../helpers/variables";
import { validateProperty } from "../validation/individual";
import { useLoaderData } from "react-router-dom";

const Property = ({ reviewForm, setReviewForm }) => {
  const { client_url, corporation_id } = useLoaderData();
  const [corporationList, setCorporationList] = useState([]);

  useEffect(() => {
    setCorporationList([]);

    async function fetchCorporationList() {
      let url = `${BACKEND_URL}/corporation_list?client_url=${client_url}`;
      let res = await fetch(url);
      /**
       * @type {Array<Object>}
       */
      let data = await res.json();
      let property = data.find((i) => i["corporationID"] === corporation_id);
      updateProperty(property);

      setCorporationList(data);
    }

    fetchCorporationList();
  }, []);

  function updateProperty(newProperty) {
    setReviewForm((prev) => ({
      ...prev,
      property: newProperty,
    }));
  }

  const property = reviewForm["property"];
  const validate = validateProperty(property);

  return (
    <Autocomplete
      value={property}
      size="small"
      options={corporationList}
      loading={corporationList.length === 0}
      getOptionLabel={(option) => option.name}
      onChange={(_, v) => updateProperty(v)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Properties"
          error={Boolean(validate)}
          helperText={validate}
        />
      )}
    />
  );
};

export default Property;
