import { ArrowForward } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Fab,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { InboxPage } from "../InboxPage/InboxPage";
import { useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../../helpers/variables";
import { enqueueSnackbar, SnackbarProvider } from "notistack";

export const Home = () => {
  const navigate = useNavigate();

  const [client, setClient] = useState(null);
  const [corporation, setCorporation] = useState(null);
  const [field, setField] = useState({
    email: "",
    password: "",
  });

  const [clientsList, setClientList] = useState([]);
  const [corporationList, setCorporationList] = useState([]);

  useEffect(() => {
    async function fetchClientList() {
      let res = await fetch(`${BACKEND_URL}/client_list`);
      let data = await res.json();
      setClientList(data);
    }

    fetchClientList();
  }, []);

  useEffect(() => {
    setCorporation(null);
    setCorporationList([]);

    async function fetchCorporationList() {
      if (client) {
        let url = `${BACKEND_URL}/corporation_list?client_url=${client["url"]}`;
        let res = await fetch(url);
        let data = await res.json();
        setCorporationList(data);
      }
    }

    fetchCorporationList();
  }, [client]);

  function updateField(e) {
    let key = e.target.name;
    let value = e.target.value;
    setField((prev) => ({ ...prev, [key]: value }));
  }

  const handleButtonClick = async () => {
    const response = await fetch(`${BACKEND_URL}/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...field,
        client_url: client["url"],
        client_token: client["token"],
        corporation_id: corporation["corporationID"],
        user_id: client["userDetails"]["userID"],
      }),
    });
    let data = await response.text();

    if (response.status === 200) {
      window.open(
        `https://snap.nimbleproperty.net/inbox?token=${data}`,
        "_blank"
      );
    } else {
      enqueueSnackbar(data, { variant: "error" });
    }
  };

  return (
    <SnackbarProvider>
      <Stack height={"100vh"} justifyContent={"center"} alignItems={"center"}>
        <Stack
          gap={3}
          width={"25%"}
          height={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <TextField
            size="small"
            fullWidth
            value={field["email"]}
            name="email"
            label="Email"
            onChange={updateField}
          />
          <TextField
            size="small"
            fullWidth
            value={field["password"]}
            name="password"
            label="Password"
            onChange={updateField}
          />
          <Autocomplete
            options={clientsList}
            value={client}
            size="small"
            fullWidth
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            onChange={(_, v) => setClient(v)}
            renderInput={(params) => <TextField {...params} label="Clients" />}
          />
          <Autocomplete
            options={corporationList}
            value={corporation}
            size="small"
            fullWidth
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            onChange={(_, v) => setCorporation(v)}
            renderInput={(params) => (
              <TextField {...params} label="Properties" />
            )}
          />
          <Box>
            <Fab
              color="primary"
              size="small"
              disabled={
                !corporation ||
                field["email"].trim() === "" ||
                field["password"].trim() === ""
              }
              onClick={
                handleButtonClick
                // () => setOpenInbox(true)
                // navigate(`/inbox/${client["url"]}/${corporation["corporationID"]}`)
              }
            >
              <ArrowForward />
            </Fab>
          </Box>
        </Stack>
      </Stack>
    </SnackbarProvider>
  );
};
