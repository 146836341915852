import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

export const TransactionType = ({ reviewForm, setReviewForm }) => {
  function updateTransactionType(type) {
    setReviewForm((prev) => ({
      ...prev,
      // payment_type: null,
      transactionType: type,
    }));
  }

  return (
    <FormControl size="small">
      <InputLabel>Transaction Type</InputLabel>
      <Select
        label="Transaction Type"
        onChange={(e) => updateTransactionType(e.target.value)}
        value={reviewForm["transactionType"]}
      >
        {["Bill Entry (default)", "Bill Pay", "Check", "Credit Card"].map(
          (transactionType, trTypeIndex) => (
            <MenuItem key={trTypeIndex} value={trTypeIndex}>
              {transactionType}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};
