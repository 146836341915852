import React from "react";
import TextField from "@mui/material/TextField";
import { NumericFormat, PatternFormat } from "react-number-format";
import { validateCheckNumber } from "../validation/individual";

const CheckNumber = ({ value, onChange }) => {
  const validate = validateCheckNumber(value);
  return (
    <PatternFormat
      value={value}
      format="#############"
      customInput={TextField}
      size="small"
      label="Check Number"
      name="check_number"
      onChange={onChange}
      error={Boolean(validate)}
      helperText={validate}
    />
  );
};

export default CheckNumber;
