import React from "react";
import { validateInvoiceNumber } from "../validation/individual";
import { IconButton, TextField } from "@mui/material";
import { CalendarMonth } from "@mui/icons-material";

const InvoiceNumber = ({ reviewForm, setReviewForm }) => {
  const { invoice_number, invoice_date = "" } = reviewForm;
  const validate = validateInvoiceNumber(invoice_number);

  function updateInvoiceNumber(value) {
    setReviewForm((prev) => ({ ...prev, invoice_number: value }));
  }

  return (
    <TextField
      name="invoice_number"
      size="small"
      value={invoice_number}
      label="Invoice Number"
      onChange={(e) => updateInvoiceNumber(e.target.value)}
      error={Boolean(validate)}
      helperText={validate}
      InputProps={{
        endAdornment: (
          <IconButton
            disabled={invoice_date === ""}
            onClick={() =>
              updateInvoiceNumber(invoice_date.replaceAll("/", ""))
            }
          >
            <CalendarMonth />
          </IconButton>
        ),
      }}
    />
  );
};

export default InvoiceNumber;
