import { createBrowserRouter } from "react-router-dom";
import { Home } from "./Home/Home";
import { InboxPage } from "./InboxPage/InboxPage";
import { BACKEND_URL } from "../helpers/variables";

async function JWTDecode(token) {
  try {
    let res = await fetch(`${BACKEND_URL}/get_claims?token=${token}`);
    let data = await res.json();
    if (!res.ok) {
      throw new Response(`Authentication failed, ${res.statusText}`, {
        status: res.status,
      });
    }
    console.log(data);
    return data;
  } catch (err) {
    throw new Response(`Authentication failed, ${err.message}`, {
      status: 500,
    });
  }
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/inbox",
    loader: async ({ request }) => {
      const url = new URL(request.url);
      const token = url.searchParams.get("token");
      return await JWTDecode(token);
    },
    element: <InboxPage />,
  },
]);

export default router;
