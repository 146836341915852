import React, { createContext, useState, useContext } from "react";

/**
 * @typedef PagesState
 * @property {number[]} checked_pages - Array of checked page numbers.
 * @property {number[]} blocked_pages - Array of blocked page numbers.
 * @property {number[]} split_pages - Array of split page numbers.
 * @property {number[]} invoice_pages - Array of invoice page numbers.
 * @property {number[]} no_of_pages - Array of Actual pdf pages.
 */

/** @type {React.Context<{pages: PagesState, updatePages: (pageType: keyof PagesState, updatedPages: number[]) => void}>} */
const PagesContext = createContext();

/**
 * PagesProvider component that provides the PagesContext to its children.
 * @param {{ children: React.ReactNode }} props
 */
export function PagesProvider({ children }) {
  /** @type {PagesState} */
  const [pages, setPages] = useState({
    checked_pages: [],
    blocked_pages: [],
    split_pages: [],
    invoice_pages: [],
    no_of_pages: [],
  });

  /**
   * State variable indicating whether file loading has failed.
   *
   * @type {[boolean, Function]}
   */
  const [fileLoadingFailed, setFileLoadingFailed] = useState(false);

  /**
   * Updates the pages array for a given page type.
   * @param {keyof PagesState} pageType - The type of pages to update (e.g., 'checked_pages').
   * @param {number[]} updatedPages - The new array of page numbers.
   */
  function updatePages(pageType, updatedPages) {
    let tempPages = { ...pages };
    tempPages[pageType] = updatedPages;
    setPages(tempPages);
  }

  const value = {
    pages,
    updatePages,
    setPages,

    fileLoadingFailed,
    setFileLoadingFailed,
  };

  return (
    <PagesContext.Provider value={value}>{children}</PagesContext.Provider>
  );
}

/**
 * Hook to use PagesContext
 * @returns {{
 *   pages: PagesState,
 *   updatePages: (pageType: keyof PagesState, updatedPages: number[]) => void,
 *   setPages: React.Dispatch<React.SetStateAction<PagesState>>
 *   fileLoadingFailed: Boolean
 *   setFileLoadingFailed: React.Dispatch<React.SetStateAction<PagesState>>
 * }}
 */
export function usePages() {
  const context = useContext(PagesContext);
  if (!context) {
    throw new Error("usePages must be used within a PagesProvider");
  }
  return context;
}
