import moment from "moment";
import * as Yup from "yup";

export const splitDetailSchema = Yup.object().shape({
  AccountCode: Yup.string().required("Account is required"),
  name: Yup.string().required("Account is required"),
  Amount: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Amount is required"),
});

export const billEntrySchema = Yup.object().shape({
  property: Yup.object().required("Property is required"),
  vendor: Yup.object().required("Vendor is required"),
  // contract: Yup.object().required("Contract is required"),

  invoice_number: Yup.string().trim().required("Invoice number is required"),
  invoice_amount: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Invoice amount is required"),

  invoice_date: Yup.string()
    .trim()
    .required("Invoice date is required")
    .test("format", "Invalid date format. Use MM/DD/YYYY", (value) => {
      return moment(value, "MM/DD/YYYY", true).isValid();
    }),

  payment_due_date: Yup.string()
    .trim()
    .required("Payment due date is required")
    .test("format", "Invalid date format. Use MM/DD/YYYY", (value) => {
      return moment(value, "MM/DD/YYYY", true).isValid();
    })
    .test(
      "is-after-invoice-date",
      "Payment due date must be after or equal to invoice date",
      function (value) {
        const { invoice_date } = this.parent;
        const invoiceDateObj = moment(invoice_date, "MM/DD/YYYY", true);
        const dueDateObj = moment(value, "MM/DD/YYYY", true);

        return dueDateObj.isSameOrAfter(invoiceDateObj);
      }
    ),

  splitDetails: Yup.array()
    .test(
      "total-amount",
      "Total amount does not match invoice amount",
      function (splitDetails) {
        const { invoice_amount } = this.parent;
        const totalAmount = splitDetails.reduce(
          (sum, detail) => sum + (detail.Amount || 0),
          0
        );
        return totalAmount === invoice_amount;
      }
    )
    .of(splitDetailSchema),
});

export const billPaySchema = Yup.object().shape({
  property: Yup.object().required("Property is required"),
  bank_account: Yup.object().required("Account is required"),
  invoice_date: Yup.string()
    .trim()
    .required("Invoice date is required")
    .test("format", "Invalid date format. Use MM/DD/YYYY", (value) => {
      return moment(value, "MM/DD/YYYY", true).isValid();
    }),
  // check_number: Yup.string().trim().required("Check number is Required"),
  selectedBillsToPay: Yup.array()
    .min(1, "You must select at least one bill to pay.")
    .required("This field is required."),
});

export const checkSchema = Yup.object().shape({
  property: Yup.object().required("Property is required"),
  vendor: Yup.object().required("Vendor is required"),
  bank_account: Yup.object().required("Account is required"),
  check_number: Yup.string().trim().required("Check number is Required"),

  invoice_date: Yup.string()
    .trim()
    .required("Invoice date is required")
    .test("format", "Invalid date format. Use MM/DD/YYYY", (value) => {
      return moment(value, "MM/DD/YYYY", true).isValid();
    }),

  invoice_amount: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Invoice amount is required"),

  splitDetails: Yup.array()
    .test(
      "total-amount",
      "Total amount does not match invoice amount",
      function (splitDetails) {
        const { invoice_amount } = this.parent;
        const totalAmount = splitDetails.reduce(
          (sum, detail) => sum + (detail.Amount || 0),
          0
        );
        return totalAmount === invoice_amount;
      }
    )
    .of(splitDetailSchema),
});

export const creditSchema = Yup.object().shape({
  property: Yup.object().required("Property is required"),
  vendor: Yup.object().required("Vendor is required"),
  bank_account: Yup.object().required("Account is required"),

  invoice_date: Yup.string()
    .trim()
    .required("Invoice date is required")
    .test("format", "Invalid date format. Use MM/DD/YYYY", (value) => {
      return moment(value, "MM/DD/YYYY", true).isValid();
    }),

  invoice_amount: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Invoice amount is required"),

  splitDetails: Yup.array()
    .test(
      "total-amount",
      "Total amount does not match invoice amount",
      function (splitDetails) {
        const { invoice_amount } = this.parent;
        const totalAmount = splitDetails.reduce(
          (sum, detail) => sum + (detail.Amount || 0),
          0
        );
        return totalAmount === invoice_amount;
      }
    )
    .of(splitDetailSchema),
});

// payment_type: Yup.mixed()
// .required("Payment type is required")
// .notOneOf([""], "Payment type is required"),
