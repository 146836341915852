import React, { useEffect, useState } from "react";
import ReactSplit, { SplitDirection } from "@devbookhq/splitter";
import { LinearProgress } from "@mui/material";
import FilePanel from "./FilePanel/FilePanel";
import FormPanel from "./FormPanel/FormPanel";
import { PagesProvider } from "../Contexts/InvoicePages";

import { SnackbarProvider } from "notistack";
import { BACKEND_URL } from "../../helpers/variables";

const ReviewPage = ({ bill }) => {
  const [invoice, setInvoice] = useState();

  useEffect(() => {
    initialLoad();
  }, [bill]);

  async function initialLoad() {
    if (bill["bill_status"] === 0) {
      fetchInvoice();
    } else {
      setInvoice({});
    }
  }

  async function fetchInvoice() {
    setInvoice();
    let res = await fetch(`${BACKEND_URL}/get_invoice?bill_id=${bill["_id"]}`);
    let data = await res.json();
    setInvoice(data);
  }

  return invoice ? (
    <SnackbarProvider>
      <PagesProvider>
        <ReactSplit
          direction={SplitDirection.Horizontal}
          initialSizes={[55, 45]}
        >
          <FilePanel bill={bill} invoice={invoice} />
          <FormPanel bill={bill} invoice={invoice} />
        </ReactSplit>
      </PagesProvider>
    </SnackbarProvider>
  ) : (
    <LinearProgress />
  );
};

export default ReviewPage;
