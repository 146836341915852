import moment from "moment";
import * as Yup from "yup";

export const confirmByUser = Yup.object().shape({
  invoice_amount: Yup.number().test(
    "confirm-amount",
    "Cancelled by user",
    function (value) {
      if (value >= 5000) {
        return window.confirm(
          "The amount is 5000 or more. Do you want to proceed?"
        );
      }
      return true;
    }
  ),

  invoice_date: Yup.string().test(
    "date-validation",
    "Cancelled by user",
    function (date) {
      const now = moment();
      const afterOneMonth = now.clone().add(1, "month");
      const beforeOneYear = now.clone().subtract(1, "year");
      let invoice_date = moment(date);

      if (
        invoice_date.isAfter(afterOneMonth) ||
        invoice_date.isBefore(beforeOneYear)
      ) {
        return window.confirm(
          "The invoice date is outside the valid range. Do you want to continue?"
        );
      }

      return true;
    }
  ),
});
