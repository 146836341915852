import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { validateBankAccount } from "../validation/individual";
import { enqueueSnackbar } from "notistack";
import { NIMBLE_URL } from "../../../../helpers/variables";

const Accounts = ({ reviewForm, setReviewForm, accounts, HEADERS }) => {
  const { bank_account, transactionType, vendor } = reviewForm;

  const labelMap = { 1: "Cards/Banks", 2: "Banks" };
  const LABEL = labelMap[transactionType] || "Cards";
  const validate = validateBankAccount(bank_account);

  useEffect(() => {
    updateAccount(null);
  }, [transactionType]);

  useEffect(() => {
    if (vendor) {
      getDefaultAccount(null);
    }
  }, [vendor]);

  function updateAccount(v) {
    setReviewForm((prev) => ({ ...prev, bank_account: v }));
  }

  async function getDefaultAccount() {
    let VendorID = vendor["id"];
    try {
      let res = await fetch(`${NIMBLE_URL}/LoadVendorPrimaryAccount`, {
        method: "POST",
        headers: HEADERS,
        body: JSON.stringify({ VendorID }),
      });
      let data = await res.json();
      if (data["status"] === 100) {
        if (data["id"]) {
          let defaultAccount = fetchList().find((i) => i["id"] === data["id"]);
          updateAccount(defaultAccount);
        }
      } else {
        enqueueSnackbar(`Error ${data["message"]}`, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar(`Error getDefaultAccount: ${error}`, {
        variant: "error",
      });
    }
  }

  function fetchList() {
    const typeMap = {
      1: ["bank", "credit card"],
      2: ["bank"],
      3: ["credit card"],
    };
    return accounts.filter((i) =>
      typeMap[transactionType].includes(i["accountTypeName"].toLowerCase())
    );
  }

  return (
    <Autocomplete
      size="small"
      value={bank_account}
      options={fetchList()}
      onChange={(e, v) => updateAccount(v)}
      getOptionLabel={(option) => option.accountDescription}
      renderInput={(params) => (
        <TextField
          {...params}
          label={LABEL}
          error={Boolean(validate)}
          helperText={validate}
        />
      )}
    />
  );
};

export default Accounts;
