// BooksDate.js
import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";

import { PatternFormat } from "react-number-format";
import { NIMBLE_URL } from "../../../../helpers/variables";
import { enqueueSnackbar } from "notistack";
import { validateBooksDate } from "../validation/individual";
import { IconButton } from "@mui/material";
import { CalendarMonth } from "@mui/icons-material";
import moment from "moment";

const BooksDate = ({ onPaste, HEADERS, reviewForm, setReviewForm }) => {
  const { isBooksDateEnabled, books_date } = reviewForm;

  useEffect(() => {
    booksDateState();
  }, []);

  async function booksDateState() {
    try {
      let res = await fetch(`${NIMBLE_URL}/IsBookDateEnabled`, {
        method: "POST",
        headers: HEADERS,
        body: JSON.stringify({}),
      });
      let data = await res.json();
      setReviewForm((prev) => ({
        ...prev,
        isBooksDateEnabled: data["status"],
      }));
    } catch (error) {
      enqueueSnackbar(`Error fetching books date status: ${error}`, {
        variant: "error",
      });
    }
  }

  const validate = validateBooksDate(books_date);

  function updateBooksDate(newValue) {
    setReviewForm((prev) => ({ ...prev, books_date: newValue }));
  }

  return (
    isBooksDateEnabled && (
      <PatternFormat
        customInput={TextField}
        size="small"
        name="books_date"
        format="##/##/####"
        value={books_date}
        onPaste={onPaste}
        onChange={(e) => updateBooksDate(e.target.value)}
        label="Books Date"
        error={Boolean(validate)}
        helperText={validate}
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={() => updateBooksDate(moment().format("MM/DD/YYYY"))}
            >
              <CalendarMonth />
            </IconButton>
          ),
        }}
      />
    )
  );
};
export default BooksDate;
