// InvoiceDate.js
import React from "react";

import { PatternFormat } from "react-number-format";
import { validateInvoiceDate } from "../validation/individual.js";
import { IconButton, TextField } from "@mui/material";
import { CalendarMonth } from "@mui/icons-material";
import moment from "moment";

const InvoiceDate = ({ value, setReviewForm, onPaste }) => {
  const validate = validateInvoiceDate(value);

  function updateInvoiceDate(newValue) {
    setReviewForm((prev) => ({ ...prev, invoice_date: newValue }));
  }

  return (
    <PatternFormat
      customInput={TextField}
      size="small"
      name="invoice_date"
      format="##/##/####"
      value={value}
      onPaste={onPaste}
      onChange={(e) => updateInvoiceDate(e.target.value)}
      label="Date"
      error={Boolean(validate)}
      helperText={validate}
      InputProps={{
        endAdornment: (
          <IconButton
            onClick={() => updateInvoiceDate(moment().format("MM/DD/YYYY"))}
          >
            <CalendarMonth />
          </IconButton>
        ),
      }}
    />
  );
};

export default InvoiceDate;
