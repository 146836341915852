import moment from "moment";

export function fetchPayLoad(finalForm, generatedS3Key, IsValidateBill) {
  let transactionType = finalForm["transactionType"];
  let invoice_number = finalForm.invoice_number?.toString()?.trim();

  let jsonData = {
    CorporationID: finalForm["property"]?.corporationID?.replace("0x", ""),
    Path1: encodeURIComponent(generatedS3Key),
  };

  if (transactionType === 0) {
    jsonData["VendorID"] = finalForm.vendor?.id?.replace("0x", "");
    jsonData["ContractID"] = finalForm["contract"]?.id;
    jsonData["BillDate"] = moment(finalForm["invoice_date"]).format(
      "MM/DD/YYYY"
    );
    jsonData["InvoiceNumber"] = invoice_number;
    jsonData["OptionalDueDate"] = finalForm["payment_due_date"];
    jsonData["Note"] = finalForm.memo;
    jsonData["ApprovalComment"] = "";
    jsonData["BooksDate"] = moment(
      finalForm["isBooksDateEnabled"]
        ? finalForm["books_date"]
        : finalForm["invoice_date"]
    ).format("MM/DD/YYYY");
    jsonData["PaymentMethodID"] = finalForm["payment_type"]?.id;
    jsonData["IsValidateBill"] = IsValidateBill;
    jsonData["IsApprove"] = finalForm["IsApprove"];
  }

  if (transactionType === 1) {
    jsonData["BankAccountID"] = finalForm["bank_account"]?.id;
    jsonData["RefNumber"] = "" + invoice_number;
    jsonData["EntryDate"] = moment(finalForm["invoice_date"]).format(
      "MM/DD/YYYY"
    );
    jsonData["IsPrintCheck"] = false;
    jsonData["BillIds"] = finalForm["selectedBillsToPay"]?.map((i) => {
      return { Billid: i["billID"] };
    });
    jsonData["CheckNumber"] = finalForm.check_number.toString().trim();
    jsonData["Amount"] = Number(finalForm.invoice_amount);
  }

  if (transactionType === 2) {
    jsonData["CheckNumber"] = finalForm.check_number.toString().trim();
    jsonData["ToBePrinted"] = false;
    // jsonData["CheckID"] =  "" // As per nimble updated it used to update the existing check entry
  }

  if (transactionType === 3) {
    jsonData["IsValidateTransaction"] = IsValidateBill;
    // jsonData["CreditCardID"] = "" // As per nimble updated it used to update the existing creditcard entry
  }

  if ([1, 2, 3].includes(transactionType)) {
    jsonData["Memo"] = finalForm.memo;
    jsonData["PaymentMethodID"] = finalForm["payment_type"]?.id;
  }

  if ([0, 2, 3].includes(transactionType)) {
    jsonData["Amount"] = Number(finalForm.invoice_amount);
    //*Add lineitems to jsondata
    jsonData["TransactionDetails"] = finalForm["splitDetails"]?.map((i) => {
      delete i["id"];
      i["Amount"] = Number(i["Amount"]);
      return i;
    });
  }

  if ([2, 3].includes(transactionType)) {
    jsonData["AccountID"] = finalForm["bank_account"]?.id;
    jsonData["PayeeID"] = finalForm.vendor?.id?.replace("0x", "");
    jsonData["PayeeType"] = finalForm.vendor.type;
    jsonData["Date"] = finalForm["invoice_date"];
    jsonData["RefrenceNumber"] = "" + invoice_number;
  }
  return jsonData;
}
