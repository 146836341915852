import React from "react";
import TextField from "@mui/material/TextField";
import { PatternFormat } from "react-number-format";
import { validatePaymentDueDate } from "../validation/individual";
import { IconButton, OutlinedInput } from "@mui/material";
import moment from "moment";
import { CalendarMonth } from "@mui/icons-material";

const MESSAGE =
  "Credit days are not Available for this vendor, Enter Credit Days :";

const PaymentDueDate = ({ reviewForm, setReviewForm, onPaste }) => {
  let { payment_due_date, invoice_date = "", vendor } = reviewForm;
  let { creditDays = "" } = vendor || {};

  const validate = validatePaymentDueDate(payment_due_date, invoice_date);

  function updatePaymentDueDate(value) {
    setReviewForm((prev) => ({ ...prev, payment_due_date: value }));
  }

  function calculatePaymentDueDate() {
    invoice_date = moment(invoice_date);

    let creditDaysInput;

    if (creditDays === "") {
      var promptValue = prompt(MESSAGE, 15);
      if (!promptValue) return;
      creditDaysInput = Number(promptValue);
    } else {
      creditDaysInput = Number(creditDays);
    }

    payment_due_date = moment(invoice_date).add(creditDaysInput, "days");

    updatePaymentDueDate(payment_due_date.format("MM/DD/YYYY"));
  }

  return (
    <PatternFormat
      customInput={TextField}
      onPaste={onPaste}
      onChange={(e) => updatePaymentDueDate(e.target.value)}
      format="##/##/####"
      name="payment_due_date"
      size="small"
      value={payment_due_date}
      label="Payment Due Date"
      error={Boolean(validate)}
      helperText={validate}
      InputProps={{
        endAdornment: (
          <IconButton
            disabled={!vendor || invoice_date === ""}
            onClick={calculatePaymentDueDate}
          >
            <CalendarMonth />
          </IconButton>
        ),
      }}
    />
  );
};

export default PaymentDueDate;
