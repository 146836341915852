// InvoiceAmount.js
import React from "react";
import TextField from "@mui/material/TextField";
import { NumericFormat } from "react-number-format";
import { validateInvoiceAmount } from "../validation/individual";

const InvoiceAmount = ({ value, onChange }) => {
  const validate = validateInvoiceAmount(value);
  return (
    <NumericFormat
      value={value}
      customInput={TextField}
      size="small"
      label="Amount"
      name="invoice_amount"
      onChange={onChange}
      error={Boolean(validate)}
      helperText={validate}
    />
  );
};
export default InvoiceAmount;
