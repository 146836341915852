import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import { confirmable, createConfirmation } from "react-confirm";

/**
 * A confirmation component that shows a message and provides actions for the user to take.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Whether to show the confirmation dialog.
 * @param {string} props.message - The message to display in the confirmation dialog.
 * @param {number} props.status - The status code associated with the confirmation.
 * @param {Function} props.cancel - Callback function to handle cancellation.
 * @param {Function} props.dismiss - Callback function to handle dismissal.
 * @param {Function} props.proceed - Callback function to handle proceeding.
 */
const Confirmation = ({ show, message, status, proceed }) => {
  console.log("OPENnnnnnnnnnnnnnnnnnnnnnnn", show);
  return (
    <Dialog open={show} onClose={() => proceed(false)}>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          size="small"
          color="success"
          onClick={() => proceed("attach_to_exists")}
        >
          Attach to exists
        </Button>
        {status === 405 && (
          <Button size="small" onClick={() => proceed("send_as_new")}>
            Send as New
          </Button>
        )}
        <Button
          size="small"
          color="warning"
          onClick={() => proceed("duplicate")}
        >
          duplicate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/**
 *
 * @param {string} message
 * @param {number} status
 */
export function ErrorDialog(message, status) {
  return createConfirmation(confirmable(Confirmation))({ message, status });
}
