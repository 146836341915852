import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Stack,
  Typography,
  Chip,
  Button,
  IconButton,
  Box,
  TableContainer,
  CircularProgress,
  DialogContent,
  Dialog,
  AppBar,
  Toolbar,
  TextField,
  MenuItem,
  Backdrop,
  Card,
  CardHeader,
} from "@mui/material";

import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  Email,
  Upload,
  Download,
  Clear,
  CalendarMonthOutlined,
} from "@mui/icons-material";
import { green } from "@mui/material/colors";
import moment from "moment";
import { useLoaderData, useParams } from "react-router-dom";
import ReviewPage from "../../Components/ReviewPage/ReviewPage";
import { BACKEND_URL } from "../../helpers/variables";

import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";

import "./InboxPage.css";
import { enqueueSnackbar, SnackbarProvider } from "notistack";
import {
  endOfDay,
  endOfWeek,
  isAfter,
  startOfDay,
  startOfWeek,
  subDays,
  subHours,
} from "date-fns";
import zIndex from "@mui/material/styles/zIndex";

/**
 * @type {EventSource}
 */
let eventSource;

const STATUS = [
  {
    value: "all",
    label: "All",
  },
  {
    value: 2,
    label: "In Approval",
  },
  {
    value: 3,
    label: "Merged",
  },
  {
    value: -2,
    label: "Extraction Failed",
  },
  {
    value: -3,
    label: "Invalid File",
  },
];

export const InboxPage = () => {
  let { client_url, corporation_id } = useLoaderData();

  const BillColor = Object.freeze({
    TO_BE_POSTED: "primary",
    IN_APPROVAL: "success",
    EXTRACTION_FAILED: "warning",
    INVALID_FILE: "error",
  });

  const BillStatus = Object.freeze({
    TO_BE_POSTED: 0,
    IN_APPROVAL: 2,
    EXTRACTION_FAILED: -2,
    INVALID_FILE: -3,
  });

  const TODAY = new Date();
  const TODAY_RANGE = [startOfDay(TODAY), TODAY];

  const [status, setStatus] = useState("all");
  const [emails, setEmails] = useState();
  const [openFileRow, setOpenFileRow] = useState();
  const [isDownloading, setIsDownloading] = useState();
  const [isBackDrop, setIsBackDrop] = useState(false);

  /**
   * @type {[Array<Date> , React.Dispatch<React.SetStateAction<number>]}
   */
  const [dateRange, setDateRange] = useState(TODAY_RANGE);
  const [selectedBill, setSelectedBill] = useState();

  const filterOptions = ["Range", "Today", "Yesterday", "Recent"];
  const [filter, SetFilter] = useState("Today");

  useEffect(() => {
    setEmails();
    if (dateRange) {
      let format = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";
      let [startDate, endDate] = dateRange;

      startDate = moment(startDate).format(format);
      endDate = moment(endDate).format(format);

      let url = `${BACKEND_URL}/emails?client_url=${client_url}&corporation_id=${corporation_id}&status=${status}&start_date=${startDate}&end_date=${endDate}`;
      createEvent(url);
    } else {
      eventSource?.close();
    }
  }, [status, dateRange]);

  async function createEvent(url) {
    setIsBackDrop(true);
    try {
      if (eventSource) eventSource.close();
      eventSource = new EventSource(url);

      eventSource.onmessage = function (event) {
        const data = JSON.parse(event.data);
        if (data.length > 0) {
          setEmails(data);
        }
        setIsBackDrop(false);
      };

      eventSource.onerror = function () {
        eventSource.close();
        setIsBackDrop(false);
      };
      return;
    } catch (err) {
      setIsBackDrop(false);
      return;
    }
  }

  async function downloadFile(file_table_id, s3_key) {
    setIsDownloading(file_table_id);

    let s3_uri = `https://nimbleocrbills.s3.us-east-1.amazonaws.com/${s3_key}`;
    let fileName = s3_uri.split("/").pop();

    try {
      const response = await fetch(s3_uri);
      const blob = await response.blob();
      const link = document.createElement("a");
      const urlObject = URL.createObjectURL(blob);

      link.href = urlObject;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(urlObject);
    } catch (error) {
      console.error("Download failed:", error);
    }
    setIsDownloading();
  }

  const TD_BORDER_COLOR = "rgba(224, 224, 224, 1)";
  const PRIMARY_COLOR = green[700];

  function EmailCardEnd({ email }) {
    return (
      <Stack gap={1} alignItems={"end"}>
        <Typography variant="body2" color={"grey"}>
          {moment(email["receivedDateTime"]).format("MMMM DD[,] YYYY hh:mmA")}
        </Typography>
        <Stack direction={"row"} gap={1}>
          {openFileRow !== email["_id"] &&
            Object.entries(BillColor).map(([status, color], index) => {
              if (email.hasOwnProperty(status)) {
                return (
                  <Chip
                    key={index}
                    color={color}
                    size="small"
                    label={`${status.replace(/_/g, " ")} (${email[status]})`}
                  />
                );
              }
            })}
        </Stack>
      </Stack>
    );
  }

  /**
   * EmailDetail component renders an email detail with a label and value.
   *
   * @param {Object} props - The props for the component.
   * @param {string} props.value - The value to display.
   * @param {string} props.label - The label to display.
   * @param {import("@mui/material").TypographyProps} props.valueProps - Props for customizing the value's typography.
   * @returns {JSX.Element} The rendered EmailDetail component.
   */
  function EmailDetail({ value, label, valueProps }) {
    return (
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        <Typography width={96} variant="body2">
          {label}
        </Typography>
        <Typography {...valueProps}>{value}</Typography>
      </Stack>
    );
  }

  function changeFilter(e) {
    let filter = e.target.value;
    SetFilter(filter);

    switch (filter) {
      case "Range":
        setDateRange();
        break;
      case "Today":
        setDateRange(TODAY_RANGE);
        break;
      case "Yesterday":
        setDateRange([
          startOfDay(subDays(TODAY, 1)),
          endOfDay(subDays(TODAY, 1)),
        ]);
        break;
      case "Recent":
        let recentHours = 2;
        setDateRange([subHours(TODAY, recentHours), TODAY]);
        break;
    }
  }

  const { afterToday } = DateRangePicker;
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Stack sx={{ height: "100vh" }}>
        <Toolbar sx={{ zIndex: 3, gap: 2 }}>
          <TextField
            select
            label="View Transactions"
            value={filter}
            onChange={changeFilter}
            size="small"
            sx={{ minWidth: 300 }}
          >
            {filterOptions.map((filter) => (
              <MenuItem key={filter} value={filter}>
                {filter}
              </MenuItem>
            ))}
          </TextField>

          {filter === "Range" && (
            <DateRangePicker
              value={dateRange}
              placeholder="From&To"
              onChange={(v) => setDateRange(v ? [v[0], endOfDay(v[1])] : v)}
              // showOneCalendar
              shouldDisableDate={afterToday()}
            />
          )}

          <TextField
            select
            label="Status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            size="small"
            sx={{ minWidth: 300 }}
          >
            {STATUS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Toolbar>

        <TableContainer sx={{ flexGrow: 1, overflow: "auto" }}>
          {Array.isArray(emails) ? (
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>File Details</TableCell>
                  <TableCell>Vendor</TableCell>
                  <TableCell>Bill Number</TableCell>
                  <TableCell>Bill Date</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Approve</TableCell>

                  <TableCell align="center" width={128}>
                    Download
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {emails.map((email) => {
                  let { bills, _id, type, no_of_files, body } = email;
                  let isEmail = type === "mail";
                  let isFileOpen = openFileRow == _id;
                  let renderedFileIDS = new Set();
                  const fileNameRowSpan = (id) =>
                    bills.filter((bill) => bill["file_table_id"] === id).length;
                  return (
                    <React.Fragment key={_id}>
                      <TableRow>
                        <TableCell colSpan={7}>
                          <Card square sx={{ boxShadow: "none" }}>
                            <CardHeader
                              avatar={isEmail ? <Email /> : <Upload />}
                              action={<EmailCardEnd email={email} />}
                              title={
                                <EmailDetail
                                  value={email["from"]}
                                  label={isEmail ? "From" : "Upload by"}
                                  valueProps={{
                                    variant: "h5",
                                    color: green[500],
                                  }}
                                />
                              }
                              subheader={
                                isEmail && (
                                  <EmailDetail
                                    value={email["subject"]}
                                    label={"Subject"}
                                    valueProps={{ variant: "body2" }}
                                  />
                                )
                              }
                            />
                          </Card>
                        </TableCell>

                        <TableCell
                          width={128}
                          sx={{ position: "relative", padding: 0 }}
                        >
                          <Box
                            position={"absolute"}
                            top={0}
                            bottom={0}
                            width={"100%"}
                          >
                            <Button
                              disableElevation
                              variant="contained"
                              color="success"
                              endIcon={
                                isFileOpen ? (
                                  <KeyboardArrowUp />
                                ) : (
                                  <KeyboardArrowDown />
                                )
                              }
                              onClick={() =>
                                isFileOpen
                                  ? setOpenFileRow(null)
                                  : setOpenFileRow(_id)
                              }
                              disabled={no_of_files === 0}
                              sx={{ height: "100%", borderRadius: 0 }}
                              fullWidth
                            >
                              {`Files (${no_of_files})`}
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>

                      {isFileOpen &&
                        bills.map((bill) => {
                          let bill_status_name = Object.keys(BillStatus).find(
                            (key) => BillStatus[key] === bill["bill_status"]
                          );

                          let { file_table_id } = bill;

                          let shouldRender = false;

                          if (!renderedFileIDS.has(file_table_id)) {
                            renderedFileIDS.add(file_table_id);
                            shouldRender = true;
                          }

                          return (
                            <TableRow key={bill["_id"]}>
                              {shouldRender && (
                                <TableCell
                                  rowSpan={fileNameRowSpan(file_table_id)}
                                >
                                  <Typography color="grey" variant="subtitle1">
                                    {bill["source_filename"]}
                                  </Typography>
                                </TableCell>
                              )}

                              <TableCell
                                sx={{
                                  borderLeft: 1,
                                  borderColor: TD_BORDER_COLOR,
                                }}
                              >
                                {bill["vendor_name"] || "-"}
                              </TableCell>

                              <TableCell>
                                {bill["invoice_number"] || "-"}
                              </TableCell>

                              <TableCell>
                                {bill["invoice_date"] || "-"}
                              </TableCell>

                              <TableCell>
                                {bill["invoice_amount"] || "-"}
                              </TableCell>

                              <TableCell>
                                <Chip
                                  size="small"
                                  color={BillColor[bill_status_name]}
                                  label={bill_status_name?.replace(/_/g, " ")}
                                />
                              </TableCell>

                              <TableCell
                                sx={{
                                  borderRight: 1,
                                  borderColor: TD_BORDER_COLOR,
                                }}
                              >
                                <Button
                                  disableElevation
                                  size="small"
                                  variant="contained"
                                  onClick={() =>
                                    setSelectedBill({
                                      ...bill,
                                      email_body: body,
                                    })
                                  }
                                >
                                  View
                                </Button>
                              </TableCell>

                              {shouldRender && (
                                <TableCell
                                  align="center"
                                  rowSpan={fileNameRowSpan(file_table_id)}
                                >
                                  {isDownloading === file_table_id ? (
                                    <CircularProgress />
                                  ) : (
                                    <IconButton
                                      disabled={Boolean(isDownloading)}
                                      onClick={() =>
                                        downloadFile(
                                          file_table_id,
                                          bill["s3_key"]
                                        )
                                      }
                                    >
                                      <Download />
                                    </IconButton>
                                  )}
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <>No mails are found</>
          )}
        </TableContainer>
      </Stack>

      <Dialog open={Boolean(selectedBill)} fullScreen>
        <AppBar position="static">
          <Toolbar variant="dense">
            <Stack direction={"row"} gap={1} alignItems={"center"} flexGrow={1}>
              <Typography variant="h5">Review</Typography>
              <Chip
                variant="outlined"
                size="small"
                label={selectedBill?.["source_filename"]}
                sx={{ color: "inherit" }}
                clickable
                onClick={() => {
                  navigator.clipboard.writeText(
                    selectedBill?.["source_filename"]
                  );
                  enqueueSnackbar("Text Copied", { variant: "info" });
                }}
              />
            </Stack>

            <IconButton color="inherit" onClick={() => setSelectedBill()}>
              <Clear />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ p: 1 }}>
          {useMemo(() => {
            return selectedBill && <ReviewPage bill={selectedBill} />;
          }, [selectedBill])}
        </DialogContent>
      </Dialog>

      <Backdrop open={isBackDrop} sx={{ zIndex: zIndex.appBar + 1 }}>
        <CircularProgress />
      </Backdrop>
    </SnackbarProvider>
  );
};
