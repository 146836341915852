import { createTheme } from "@mui/material";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
// uploadBtn: createColor("#6d7de0"),
const theme = createTheme({
  // palette: {

  // },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 5px 15px -3px rgba(0, 0, 0, 0.05)",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "0px 5px 15px -3px rgba(0, 0, 0, 0.05)",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0px 5px 15px -3px rgba(0, 0, 0, 0.05)",
        },
      },
    },
  },
  typography: {
    fontFamily: "Poppins-Regular, sans-serif",
  },
});

export default theme;
