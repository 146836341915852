import React, { useEffect, useMemo, useState } from "react";
import { usePages } from "../../Contexts/InvoicePages";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Toolbar,
} from "@mui/material";
import { DraftsOutlined, MailOutline } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import _ from "lodash";

import { BACKEND_URL } from "../../../helpers/variables";
import { blue, green, grey, indigo, yellow } from "@mui/material/colors";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";

import { getFilePlugin } from "@react-pdf-viewer/get-file";
import { rotatePlugin } from "@react-pdf-viewer/rotate";

import { zoomPlugin } from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/zoom/lib/styles/index.css";

const FilePanel = ({ bill, invoice }) => {
  const { file_table_id, bill_status, s3_key } = bill;
  const { page_numbers } = invoice || {};

  const url = `https://nimbleocrbills.s3.us-east-1.amazonaws.com/${s3_key}`;

  const defaultStyles = {
    position: "absolute",
    border: "1px solid red",
    display: "none",
    pointerEvents: "none",
    boxSizing: "border-box",
    zIndex: 1100,
  };

  const [styles, setStyles] = useState(defaultStyles);
  const [boundingBoxes, setBoundingBoxes] = useState([]);

  const { pages, updatePages, setPages, setFileLoadingFailed } = usePages();
  const {
    checked_pages,
    blocked_pages,
    split_pages,
    invoice_pages,
    no_of_pages,
  } = pages;

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage, CurrentPageLabel } = pageNavigationPluginInstance;

  const getFilePluginInstance = getFilePlugin();
  const { Download } = getFilePluginInstance;

  const rotatePluginInstance = rotatePlugin();
  const { Rotate } = rotatePluginInstance;

  const zoomPluginInstance = zoomPlugin();
  const { Zoom } = zoomPluginInstance;

  /**
   *
   * @param {import("@react-pdf-viewer/core").DocumentLoadEvent} param0
   */
  async function onDocumentLoad({ doc: { numPages } }) {
    let no_of_pages = Array.from({ length: numPages }, (_, k) => k + 1);

    if (no_of_pages.length >= 2 && page_numbers && page_numbers[0] !== 1) {
      jumpToPage(page_numbers[0] - 1);
    }

    if (bill_status !== -2) {
      fetchOCR_Response();
    }

    try {
      let { invoice_pages, splitted_pages, locked_pages } =
        await getPageProperties();

      let combined = locked_pages;
      if (bill_status !== 0) {
        combined = _.union(invoice_pages, splitted_pages, locked_pages);
      }

      let filtered_no_of_pages = _.sortBy(_.difference(no_of_pages, combined));

      if (filtered_no_of_pages[0] !== 1) {
        jumpToPage(filtered_no_of_pages[0] - 1);
      }

      setPages((prev) => ({
        ...prev,
        no_of_pages,
        blocked_pages: locked_pages,
        ...(page_numbers && { checked_pages: page_numbers }),
        ...(bill_status !== 0 && { invoice_pages: invoice_pages }),
        ...(bill_status !== 0 && { split_pages: splitted_pages }),
      }));
    } catch (error) {
      enqueueSnackbar(`Failed to fetch page properties: ${error}`, {
        variant: "error",
      });
    }
  }

  async function fetchOCR_Response() {
    let res = await fetch(
      `${BACKEND_URL}/get_ocr_response?file_table_id=${bill["file_table_id"]}`
    );
    let data = await res.json();
    setBoundingBoxes(data);
  }

  async function getPageProperties() {
    const res = await fetch(
      `${BACKEND_URL}/page_properties?file_table_id=${file_table_id}`
    );
    let data = await res.json();
    return data;
  }

  const getBoundingBox = (e, pageNumber) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    let page = boundingBoxes.find((i) => i["page_number"] === pageNumber) || {
      page_blocks: [],
    };

    return page["page_blocks"].find(
      (box) =>
        x >= box.Left * rect.width &&
        x <= (box.Left + box.Width) * rect.width &&
        y >= box.Top * rect.height &&
        y <= (box.Top + box.Height) * rect.height
    );
  };

  function onMouseMoveHandler(e, pageIndex) {
    let rect = e.target.getBoundingClientRect();
    let boundingBox = getBoundingBox(e, pageIndex);

    if (boundingBox) {
      setStyles((prev) => ({
        ...prev,
        display: "block",
        width: `${boundingBox.Width * rect.width}px`,
        height: `${boundingBox.Height * rect.height}px`,
        top: `${boundingBox.Top * rect.height}px`,
        left: `${boundingBox.Left * rect.width}px`,
      }));
    } else {
      setStyles(defaultStyles);
    }
  }

  function copyTextFromPDf(e, pageIndex) {
    let boundingBox = getBoundingBox(e, pageIndex);
    if (boundingBox) {
      let text = boundingBox["Text"];
      navigator.clipboard.writeText(text);
      enqueueSnackbar(`"${text}" copied to clipboard`, { variant: "info" });
    }
  }

  function updateCheckedPages(page) {
    let newCheckedPages;
    if (checked_pages.includes(page)) {
      newCheckedPages = checked_pages.filter((i) => i !== page);
    } else {
      newCheckedPages = [...checked_pages, page];
    }
    updatePages("checked_pages", newCheckedPages);
  }

  /**
   *
   * @param {import("@react-pdf-viewer/core").RenderPageProps} props
   * @returns
   */
  function PageRender(props) {
    let pageNumber = Number(props["pageIndex"]) + 1;
    return (
      <>
        {props.canvasLayer.children}
        {props.textLayer.children}

        <Box
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 1000,
            cursor: "pointer",
          }}
          onMouseMove={(e) => onMouseMoveHandler(e, pageNumber)}
          onDoubleClick={(e) => copyTextFromPDf(e, pageNumber)}
        />
        <Box style={styles} />
      </>
    );
  }

  const tabFontColor = (page) =>
    checked_pages.includes(page) || blocked_pages.includes(page)
      ? "white"
      : "inherit";

  const tabBorderColor = (page) =>
    checked_pages.includes(page) || blocked_pages.includes(page)
      ? undefined
      : "divider";

  function isAllPagesSelected() {
    let all_pages = _.union(
      checked_pages,
      blocked_pages,
      split_pages,
      invoice_pages
    );
    return _.isEqual(_.sortBy(all_pages), _.sortBy(no_of_pages));
  }

  function unmodifiedPages() {
    const combined = _.union(blocked_pages, split_pages, invoice_pages);
    return _.difference(no_of_pages, combined);
  }

  /**
   *
   * @param {import("react").ChangeEvent} e
   */
  function checkAllPages(e) {
    let newCheckedPages = unmodifiedPages();

    let checked = e.target.checked;
    if (checked) {
      updatePages("checked_pages", newCheckedPages);
    } else {
      updatePages("checked_pages", []);
    }
  }

  const [openMailBody, setMailBody] = useState(false);

  return (
    <Stack height={"100%"} border={1} borderColor={"divider"}>
      <Toolbar
        sx={{ gap: 1, bgcolor: indigo[50], alignItems: "center" }}
        variant="dense"
      >
        {useMemo(() => {
          return (
            <CurrentPageLabel>
              {(props) => (
                <Tabs
                  sx={{ flexGrow: 1, alignItems: "center" }}
                  value={props.currentPage}
                  variant="scrollable"
                  textColor={undefined}
                  TabIndicatorProps={{
                    style: { display: "none" },
                  }}
                >
                  {no_of_pages.map((page, index) => (
                    <Tab
                      key={index}
                      label={page}
                      sx={{
                        color: tabFontColor(page),
                        "&.Mui-selected": {
                          color: tabFontColor(page),
                        },
                        bgcolor: checked_pages.includes(page)
                          ? yellow[700]
                          : blocked_pages.includes(page)
                          ? green[700]
                          : invoice_pages.includes(page) && grey[700],
                        minWidth: 24,
                        minHeight: 24,
                        borderRadius: "50%",
                        mr: 1,
                        border: 1,
                        borderColor: tabBorderColor(page),
                      }}
                      onClick={() => jumpToPage(index)}
                    />
                  ))}
                </Tabs>
              )}
            </CurrentPageLabel>
          );
        }, [pages])}
        <Box flexGrow={1} />
        <Zoom />
        <Rotate />
        <Download />
        <IconButton onClick={() => setMailBody((prev) => !prev)}>
          {openMailBody ? <DraftsOutlined /> : <MailOutline />}
        </IconButton>
        <Checkbox
          disabled={unmodifiedPages().length === 0}
          checked={isAllPagesSelected()}
          onChange={(e) => checkAllPages(e)}
        />
      </Toolbar>
      {openMailBody && (
        <Box
          p={1}
          border={1}
          borderColor={"divider"}
          height={8 * 36}
          overflow={"auto"}
          dangerouslySetInnerHTML={{ __html: bill["email_body"] }}
        />
      )}
      <Stack direction={"row"} flexGrow={1} overflow={"auto"}>
        <Box width={104} p={1} borderRight={1} borderColor={"divider"}>
          {useMemo(() => {
            return (
              <CurrentPageLabel>
                {({ currentPage }) => {
                  let page = currentPage + 1;
                  return (
                    <FormControlLabel
                      disabled={[
                        blocked_pages,
                        split_pages,
                        invoice_pages,
                      ].some((arr) => arr.includes(page))}
                      checked={checked_pages.includes(page)}
                      control={
                        <Checkbox onClick={() => updateCheckedPages(page)} />
                      }
                      label={page}
                      labelPlacement="top"
                    />
                  );
                }}
              </CurrentPageLabel>
            );
          }, [pages])}
        </Box>

        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Viewer
            onDocumentLoad={(e) => onDocumentLoad(e)}
            fileUrl={url}
            plugins={[
              pageNavigationPluginInstance,
              getFilePluginInstance,
              rotatePluginInstance,
              zoomPluginInstance,
            ]}
            renderPage={PageRender}
            renderError={({ message, name }) => {
              enqueueSnackbar(message, { variant: "error" });
              setFileLoadingFailed(true);
            }}
          />
        </Worker>
      </Stack>
    </Stack>
  );
};

export default FilePanel;
