import * as Yup from "yup";
import moment from "moment";

export const BooksDateScheme = Yup.object().shape({
  books_date: Yup.string()
    .trim()
    .required("Books date is required")
    .test("format", "Invalid date format. Use MM/DD/YYYY", (value) => {
      return moment(value, "MM/DD/YYYY", true).isValid();
    }),
});
